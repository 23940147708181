import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-use';
import { isEmpty, join } from 'ramda';
import * as Routes from 'Routes';
import { authenticityToken } from 'utils/FetchHelpers';

import Icon from 'components/Icon';
import Flash from 'components/Flash';

import PasswordFields from '../sharedComponents/PasswordFields';

import useStyles from './useStyles';

const ResetPassword = ({ errors, token }) => {
  const classes = useStyles();
  const urlToken = useLocation().search.split('=') || [];

  return (
    <>
      <Helmet>
        <title>Set Your Password</title>
      </Helmet>
      <div className={classes.form}>
        <Icon icon="authentication" />
        <div className={classes.title}>Let's set your new password!</div>
        {!isEmpty(errors) && <Flash flash={['alert', join(', ', errors)]} />}
        <form action={Routes.user_password_path()} method="post" className={classes.form}>
          <input type="hidden" name="authenticity_token" value={authenticityToken()} />
          <input type="hidden" name="user[reset_password_token]" value={token || urlToken[1]} />
          <input type="hidden" name="_method" value="put" />
          <PasswordFields backToLogin buttonText="Set New Password" />
        </form>
      </div>
    </>
  );
};

ResetPassword.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  token: PropTypes.string,
};

export default ResetPassword;
